import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SingleTestimonial from "../components/testimonials/singleTestimonial"
import {
  GridTwo,
  GridAuto,
  GridThree,
  Section,
  Container,
} from "../components/layoutComponents"
import Title from "../components/title"
import CallToAction from "../components/banners/callToAction"

import ActionBanner from "../images/action-banner.png"

export default function Testimonials() {
  return (
    <Layout>
      <SEO title="Testimonials" />
      <Section>
        <Container>
          <Title title="glowing reviews from our happy customers" />
          <GridThree>
            <SingleTestimonial
              testimonial="The team at Royco Electric always deliver exemplary service. Prompt and professional, they have extensive knowledge in all aspects of their trade that spans many properties types – commercial, residential, mixed-use, and more. Their quality of work is of the highest standard and they’re willing to be flexible to fit your needs. 100% would recommend them to anyone looking for a reliable electrician."
              company="BJL Properties Inc."
              name="Krystal Bedard"
            />
            <SingleTestimonial
              testimonial="James Ross of Royco Electric has a great team of qualified electricians. They have handled a lot of my projects from basements, main floor and kitchen renovations. If I need a service changed to 200 amps they could handle it. They work with efficiency and projects are always done within the time frame specified. Inspections are always passed without deficiencies. Royco always leaves the jobsite clean and tidy. I give Royco Electric five stars."
              company="Finished Basements"
              name="Jerome Sammy"
            />
            <SingleTestimonial
              testimonial="Good contractors are really hard to find and I’ve been fortunate through referrals from people familiar with or in the trades. Royco Electric is not only reasonable in price, but James is upfront and honest from the start. His crew was professional and were fun to be around. If you need electrical work done in Toronto, this is the company you should reach out to."
              company="homeowner"
              name="Craig Hadley"
            />
            <SingleTestimonial
              testimonial="We have hired Royco Electric to complete some electrical work for multiple projects we are working on. He has worked with us and also directly with our clients. James and his team are always extremely professional and easy to work with. They are respectful of the spaces they work in and are efficient at their job but also take the time to make sure their work is perfect. They trouble shoot problems seamlessly and are always on time. Overall, it is always a pleasure working with Royco Electric."
              company="Harrison Fae Design"
              name="Alicia Marchioni"
            />
            <SingleTestimonial
              testimonial="We use Royco for any and all of our electrical issues with our commercial and residential units. Russ and James are fast, accommodatable, professional, friendly and very honest."
              company="Henderson Property Management"
              name="Danielle Blackie"
            />
            <SingleTestimonial
              testimonial="Excellent work executed to electrical safety regulation standards, quick to respond, friendly, adaptable and a strong resource for lighting concepts and layouts."
              company="homeowner"
              name="Rosalind Salter"
            />
            <SingleTestimonial
              testimonial="Extremely professional team and clean, beautiful work that is always safe and to code. Never seen anyone make a panel look like a piece of art!"
              company="homeowner"
              name="sara goodfellow"
            />
            <SingleTestimonial
              testimonial="I highly recommend James as he is incredibly easy to work with. James is quick to respond through both emails and phone calls, and all deadlines set between myself and James were met in a timely matter which allowed the project to run smoothly and on time. James and the Royco Electric team clearly care about their customers and vendors and I was treated with respect through all my dealings with James. I look forward to continuing working with James!"
              company="Aaron Tonner Web Solutions"
              name="Aaron Tonner"
            />
          </GridThree>
        </Container>
      </Section>
      <CallToAction
        img={ActionBanner}
        textPrimary="hire trusted electricians"
        // textPrimary="want to experience our exceptional services?"
        textSecondary="Request a service, schedule an appointment, or get a quote on your commercial, residential, or industrial electrical projects."
      />
    </Layout>
  )
}
