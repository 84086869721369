import React from "react"
import styled from "styled-components"
import Stars from "./stars"
import Avatar from "./avatar"

const Wrapper = styled.div`
  background: var(--clr-light-secondary);
  border-radius: 20px;
  padding: 2em;
`

export default function SingleTestimonial(props) {
  return (
    <Wrapper className="spacing">
      <Stars />
      <p className="italics">"{props.testimonial}"</p>
      <Avatar company={props.company} name={props.name} />
    </Wrapper>
  )
}
